import instance from "@/axios"

/** 拍品列表 */
export const getEditedNewAuctionData = async (productName) => {
    return instance({
        url: '/dq_admin/product/auctionGoodsList',
        method: 'GET',
        params: {
            pageSize: 2,
            pageNum: 1,
            productName: productName
        }
    })
}
/** 获取心理价加价幅度 */
export const getAuctionHeartPriceList = async () => {
    return instance({
        url: '/dq_admin/product/listIncrPriceList',
        method: 'GET',
    })
}

/** 编辑瑕疵---拍品 */
export const postAuctionRemark = async (params) => {
    return instance({
        url: '/dq_admin/product/editNormalProperty',
        method: 'POST',
        data: params
    })
}
/** 编辑瑕疵----一口价 */
export const postAuctionRemarkByFixedPrice = async (params) => {
    return instance({
        url: '/dq_admin/product/editNormalProperty',
        method: 'POST',
        data: params
    })
}

/** 拍品审核 */
export const postAuctionCheckStatusApi = async (params) => {
    return instance({
        url: '/dq_admin/product/reviewAuctionGood',
        method: 'POST',
        data: params
    })
}

/** 获取拍品是否能进行二次驳回 */
export const getAuctionLastTimeCheckStatusApi = async (productId, productRemark) => {
    return instance({
        url: '/dq_admin/product/judgeHistoryReview',
        method: 'post',
        data: {
            productId: productId,
            operType: "refuse",
            productRemark: productRemark
        }
    })
}

/** 图片识别 */
export const getTempImgInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/product/recoCategoryCoin',
        method: 'GET',
        params: params
    })
}